import en from "./locales/en.json";
import dk from "./locales/dk.json";

export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'dk',
    messages: {
        //en,
        dk
    }
}));